body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
  }

.centered-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 95vh;

  border-radius: 20px;
}
.left-aligned-content {
  display: flex;
 
}
.sub-content{
font-size: 16px;
cursor: pointer;
}
.img-content {
  margin-left: auto;
  cursor: pointer;
  
}



.mainheading{
  font-weight: 300;
  font-size: 3em;
}


.dot {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 2s ease;
    animation-duration: 2s;
  }
  
  .active {
    background-color: #f5f5f5;
  }
  
  
  .slider {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .slider-controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 200px;
    text-align: center;
    transform: translateX(-50%);
    z-index: 1000;
    list-style: none;
    text-align: center;
  }
  
  .slider input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-controls label {
    display: inline-block;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #212121;
    transition: background-color 0.2s linear;
  }
  
 
  
  .slides {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    
background-color: rgb(143, 210, 215);
 
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 2s ease; /* Transition for fade effect only */
    background-color: transparent;
   
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  #btn-1:checked ~ .slides .slide:nth-child(1) {
    animation: fade-in 5s ease; /* Adjust the duration as needed */
    opacity: 1;
   
    
  }
  
  #btn-2:checked ~ .slides .slide:nth-child(2) {
    animation: fade-in 5s ease; /* Adjust the duration as needed */
    opacity: 1;
   
  }
  
  #btn-3:checked ~ .slides .slide:nth-child(3) {
    animation: fade-in 5s ease; /* Adjust the duration as needed */
    opacity: 1;
  }
  
  
  .slide-content {
    width: 400px;
    margin-left: 6vw;
   
  }
  
  .slide-title {
    margin-bottom: 20px;
    font-size: 36px;
    margin-top: 10vw;
  }
  
  .slide-text {
    margin-bottom: 20px;
  }
  
  .slide-link {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    border-radius: 3px;
    text-decoration: none;
    background-color: none;
    background: transparent;
    border: none;
    font-size: 23px;
    font-family: "Roboto";
    cursor: pointer;
    border-bottom: 1px solid white;
    padding: 0px;
  }

  
  .slide-image img {
    max-width: 100%;
  }
  
  