
  .flex-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    margin-top: 30px;
   
  }
  .Bottomnav{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

 
   
  }
 
  h2{
    color: white;
    margin-left: 45px;
    margin-top: 10px;
  }
  .output {
    box-shadow: 5px 10px 18px #7b4982;
border-radius: 15px;
    border:#3a4581;
    border-style: solid;
  border-width: 2px;
    background-color: #6a6a7b;
    width: 160px;
    height: 70px;
    margin: 10px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-size: 17px;
    font-weight: bold;
    
    opacity: 0.8;
  }

  .input {
   
        width: 12vw;
        height: 50px;
        border-radius: 5px;
        text-align: center;
    
        color: rgb(0, 0, 0);
        font-size: 19px;
       
  
      }
   

   
      .td{font-weight: bold;
      font-size: 20px;
    color: grey;}

      .forecast{
        background-image: linear-gradient(to bottom right,#1CBDCB,#0A197E);
        width: 180px;
        height: 65px;
        border-radius: 5px;
      
        border-style: solid;
        color: white;
        font-size: 17px;
        font-weight: normal;
        margin-top: 63px;
      }








  .vtt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

.boxdesign{
    text-align:center;
      border: 2px solid rgb(150, 152, 147);
      border-radius:20px;
       padding:45px;
       background-color: black;
opacity: 0.9;
      
}
.rpaleftbtn{
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 65%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 70px;
padding-bottom: 70px;

text-align: center;
  border-radius: 12px;
    font-size: 28px;
    font-weight : bolder;
    color: white;
    border: solid 3px #265f92;
     background-color: #43434a; 
    box-shadow: 5px 10px 18px #7b4982;  
    opacity: 0.8;
 }
 .rparightbtn{
  cursor: pointer;
  justify-content: center;
  align-items: center;

  padding-left: 110px;
  padding-right: 110px;
  padding-top: 66px;
padding-bottom: 65px;
text-align: center;
  border-radius: 12px;
 
    font-size: 28px;
    font-weight : bolder;
    color: white;
    border: solid 3px #265f92;
     background-color: #43434a; 
    box-shadow: 5px 10px 18px #7b4982;  
    opacity: 0.9;
 }
.Rpaboxdesign{
    text-align:center;
      border: 1px solid rgba(18,17,53,255);
      border-radius:20px;
       padding:120px;
       background-color: rgba(18,17,53,255);
opacity: 0.9;
      
}
.buttonstyle {

  align-items: center;
  text-align: center;
  border-radius: 100rem;
  height: 90px;
  cursor: pointer;
  justify-content: center;
  width: 210px;
  padding: 10px 15px;
  margin: 10px 15px;
  font-size: 18px;
  font-weight: bolder;
  border-radius: 12px;
  color: white;
  border: solid 3px #265f92;
  background-color: #43434a;
  box-shadow: 5px 10px 18px #7b4982;
  opacity: 0.9;
}

  .h3style{
    color:white
  }
  .pstyle{
    color: white;
    padding-right: 20px;
  
    font-weight: bold;
    font-size: 20px;
  }

